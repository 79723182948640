import React, { useState, useEffect, useContext } from "react";
import $ from "jquery";
import AvatarPink from "../../../img/avatarPink.png";
import { StateContext } from "../../StateContainer";
import Moment from "react-moment";
import { TbDotsVertical as Dots } from "react-icons/tb";
import Loading from "../../Loading";
import ReactDOM from "react-dom";
import { marked } from "marked";  // Importa la libreria marked


const TableSql = ({
  text,
  lastMessage,
  ButtonNavigation,
  row,
  primaryRGBA,
  index,
  ControlsBeforeSendingMessage,
}) => {
  const { dettagliProgetto, setIsTypingMachine, loading } =
    useContext(StateContext);

    marked.setOptions({
      gfm: true, // Abilita il Markdown GitHub-style
      breaks: true, // Gestisce i ritorni a capo
      tables: true, // Abilita le tabelle
    });



  return (
    <li className="pb-5 message-bot bg-orange">

      <div className="d-flex align-items-start gap-2">
        <div
          style={{
            height: "2.4vw",
            width: "2.4vw",
            minHeight: "16px",
            minWidth: "16px",
            borderRadius: "100%",
            background: `url(${
              dettagliProgetto && dettagliProgetto.chatbotIcon
                ? dettagliProgetto.chatbotIcon
                : AvatarPink
            }) center center / cover no-repeat`,
          }}
          className=""
        ></div>

        
        <div className="m-0 p-0 w-auto">
        
          <h5 className="font-size-14 conversation-name">
            <a href="#" className="text-dark text-decoration-none">
              {dettagliProgetto ? dettagliProgetto.assistantName : ""}
            </a>{" "}
          </h5>

    
            <div
              className="conversation-list m-0"
              style={{ width: "100%"}}
              key={index}
            >
              
              <div className="ctext-wrap mt-2 m-0" style={{overflowX: "auto"}}>
              <table
                    className="table table-dark"
                    style={{ width: "auto"}}
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: row.botMessage.sql_table_content
                    }}
                  ></table>
              </div>
              <div>
                {/* Nuovo codice  */}
                <div
              className="conversation-list m-0"
              style={{ maxWidth: "400px" }}
              key={index}
            >
              <div className="ctext-wrap mt-2 m-0">
                <div
                  className="ctext-wrap-content m-0 text-dark mb-1"
                  style={{
                    background: `${primaryRGBA}`,
                    maxWidth: "300px",
                    hyphens: "auto",
                  }}
                >
                  <div
                    className=""
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: row.botMessage.link_gestionale}}
                  ></div>
                </div>
              </div>
            </div>
 
            </div>
            </div>
        
        </div>
      </div>
      <span
        className="d-inline-block font-size-12 text-muted"
        style={{ marginLeft: "24px" }}
      >
        <Moment format="HH:mm">{row.botMessage.date}</Moment>
      </span>


     
    </li>
  );
};

export default TableSql;
