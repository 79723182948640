import React, { useState, useEffect, useRef, useContext } from "react";
import { TbDotsVertical as Dots, TbH1 } from "react-icons/tb";
import { IoMdSend as Invia } from "react-icons/io";
import axios from "axios";
import "./App.css";
import ChatRegistrata from "./components/ChatRegistrata";
import NuovaChat from "./components/NuovaChat";
import useSpeechRecognition from "./components/hooks/UseSpeechRecognition";
import { FaMicrophone, FaDotCircle } from "react-icons/fa";
import { StateContext } from "./components/StateContainer";
import AWS from "aws-sdk";

const DemoSpeech = ({ content }) => {
  const {
    text,
    startListening,
    stopListening,
    isListening,
    hasRecognitionSupport,
  } = useSpeechRecognition();
  const [error, setError] = useState("");
  const [totalChat, setTotalChat] = useState([]);
  const [projectCode, setProjectCode] = useState("");
  const [conversationId, setConversationId] = useState(null);
  const [caricamento, setCaricamento] = useState(false);
  const [coloreMessaggi, setColoreMessaggi] = useState("");

  const userLastMessageRef = useRef("");

  const { dettagliProgetto, setDettagliProgetto, message, setMessage } =
    useContext(StateContext);

  if (conversationId === null) {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 100000);
    setConversationId(`${timestamp}-${random}`);
  }
  useEffect(() => {
    const url = new URL(window.location.href);
    setProjectCode(url.searchParams.get("projectCode"));
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);

    setProjectCode(url.searchParams.get("projectCode"));
  }, []);

  useEffect(() => {
    setCaricamento(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/public/project/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        projectCode: projectCode,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          // window.location.replace("/login");
        } else {
          setError("");
          setCaricamento(false);
          setDettagliProgetto(response.data.response);
        }
      })
      .catch((err) => setError(err));
  }, [projectCode]);

  console.log(dettagliProgetto);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = (event) => {
    let chatStorico = totalChat.slice();
    if (chatStorico.length > 4) {
      chatStorico.shift();
    }
    const userMessage = message.trim();
    if (userMessage === "") return;

    userLastMessageRef.current = userMessage;
    setMessage("");

    const conversation = {
      userMessage: userMessage,
      botMessage: "Sto pensando ...",
    };
    setTotalChat((prevTotal) => [...prevTotal, conversation]);

    const apiURL = `${process.env.REACT_APP_PYTHON_ENGINE}/chatbot`;

    const requestOptions = {
      method: "POST", // Puoi impostare il metodo come 'GET' o 'POST' in base alle tue esigenze
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: userMessage,
        sessionId: conversationId,
        projectCode: dettagliProgetto.code,
        istruzioniChatbot: dettagliProgetto.basePrompt,
        temperature: dettagliProgetto.temperature,
        analysisDepth: dettagliProgetto.analysisDepth,
        messaggi: chatStorico,
      }),
    };

    fetch(apiURL, requestOptions).then(async (response) => {
      conversation.botMessage = "";

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      const readChunk = async () => {
        let previousChunk = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          let chunk = decoder.decode(value, { stream: true });
          chunk = chunk.replace(/"/g, "").replace(/\\n\\n/, "");
          conversation.botMessage += chunk;

          // Concatena il chunk corrente con il chunk precedente
          const fullText = previousChunk + chunk;

          const sentences = fullText.split(/[,.?!]/);
          const lastIndex = sentences.length - 1;
          const lastSentence = sentences[lastIndex];

          if (lastIndex === 0 || lastSentence.endsWith(" ")) {
            // Se l'ultima frase termina con uno spazio, conserva il chunk corrente
            previousChunk = fullText;
          } else {
            // Altrimenti, l'ultima frase è completa e può essere passata
            sentences.forEach(async (sentence) => {
              await handleConvert(sentence.trim());
            });
            previousChunk = "";
          }

          setTotalChat((prevTotal) => [...prevTotal]); // Aggiorna lo stato di totalChat
        }
      };

      try {
        await readChunk();
      } catch (error) {
        console.error("Errore durante la lettura dei chunk:", error);
      }
    });
  };

  const cardBodyRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  const handleScroll = () => {
    const cardBody = cardBodyRef.current;
    setIsUserScrolling(
      cardBody.scrollTop !== cardBody.scrollHeight - cardBody.offsetHeight
    );
  };

  useEffect(() => {
    const cardBody = cardBodyRef.current;
    if (!isUserScrolling) {
      cardBody.scrollTop = cardBody.scrollHeight;
    }
  }, [totalChat, isUserScrolling]);

  let audioPlayer = new Audio(); // Dichiarare all'esterno di handleSendMessage
  audioPlayer.queue = []; // Inizializza la coda di riproduzione

  const handleConvert = async (text) => {
    AWS.config.update({
      accessKeyId: "AKIAWUMGMHNS5U4LF5K7",
      secretAccessKey: "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",
      region: "eu-west-1",
    });

    const Polly = new AWS.Polly();
    try {
      const params = {
        OutputFormat: "mp3",
        Text: `<speak><prosody rate="fast">${text}</prosody></speak>`,
        TextType: "ssml",
        VoiceId: "Bianca",
      };

      Polly.synthesizeSpeech(params, (err, data) => {
        if (err) {
          console.error("Errore durante la conversione del testo:", err);
          return;
        }

        const uInt8Array = new Uint8Array(data.AudioStream);
        const blob = new Blob([uInt8Array.buffer], { type: "audio/mp3" });
        const audioUrl = URL.createObjectURL(blob);

        audioPlayer.queue.push(audioUrl);

        // Riproduci l'audio solo se non è già in riproduzione
        if (!audioPlayer.paused) {
          return; // Se l'audio è in riproduzione, esce dalla funzione
        }

        const playNext = () => {
          if (audioPlayer.queue.length > 0) {
            const nextAudioUrl = audioPlayer.queue.shift();
            audioPlayer.src = nextAudioUrl;
            audioPlayer.play().catch((error) => {
              console.error(
                "Errore durante la riproduzione dell'audio:",
                error
              );
            });
          } else {
            audioPlayer.removeEventListener("ended", playNext);
          }
        };

        audioPlayer.addEventListener("ended", playNext);
        playNext();
      });
    } catch (error) {
      console.error("Errore durante la conversione del testo:", error);
    }
  };

  return (
    <div className='App'>
      <div id='layout-wrapper'>
        <div className='main-content m-0 p-0'>
          <div className='page-content p-0'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12 px-0'>
                  <div className='w-100 user-chat mt-sm-0 ms-lg-1'>
                    <div className='card' style={{ height: "800px" }}>
                      <div className='p-3 px-lg-4 border-bottom'>
                        <div className='row'>
                          <div className='col-md-4 col-6'>
                            <h5 className='font-size-16 mb-1 fw-bold'>
                              Chatbot{" "}
                              {dettagliProgetto ? dettagliProgetto.title : ""}
                            </h5>
                            <p className='text-muted text-truncate mb-0'>
                              Scopri direttamente le capacità della Chatbot
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        ref={cardBodyRef}
                        style={{ overflowY: "auto" }}
                        className='card-body'
                      >
                        {caricamento ? (
                          <div className='form-outline mt-5'>
                            <div className='loading-dots'>
                              <div className='loading-dots--dot'></div>
                              <div className='loading-dots--dot'></div>
                              <div className='loading-dots--dot'></div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className='chat-conversation py-3'>
                              <ul
                                className='list-unstyled mb-0 chat-conversation-message px-3'
                                data-simplebar
                              >
                                {dettagliProgetto &&
                                dettagliProgetto.creditsLeft === 0 ? (
                                  <li>
                                    <div className='conversation-list'>
                                      <div
                                        class='alert alert-danger'
                                        role='alert'
                                      >
                                        Hai terminato i crediti a tua
                                        disposizione. Per continuare a
                                        utilizzare la chatbot, rinnova il tuo
                                        piano mensile
                                      </div>
                                    </div>
                                  </li>
                                ) : (
                                  <NuovaChat chat={totalChat} />
                                )}
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                      <div className='py-3' style={{ opacity: 0.9 }}></div>
                      {caricamento ? (
                        ""
                      ) : dettagliProgetto &&
                        dettagliProgetto.creditsLeft === 0 ? (
                        ""
                      ) : (
                        <div
                          className={`py-4 card-footer chat-input-section d-flex flex-fill align-items-center w-100  justify-content-center bg-white footer-chat`}
                        >
                          <div className='row w-100 d-flex justify-content-center'>
                            <div className='w-100 col'>
                              <div className='position-relative'>
                                <input
                                  type='text'
                                  className='form-control search-bar rounded-pill w-100'
                                  placeholder={`Invia un messaggio al bot`}
                                  value={message}
                                  onChange={(event) =>
                                    setMessage(event.target.value)
                                  }
                                  onKeyDown={handleKeyDown}
                                />
                                {!isListening ? (
                                  <span
                                    className='m-0 micro-icon d-flex align-items-center justify-content-center'
                                    onClick={startListening}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FaMicrophone />
                                  </span>
                                ) : (
                                  <span
                                    className='m-0 micro-icon d-flex align-items-center justify-content-center text-danger registration'
                                    onClick={stopListening}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FaDotCircle />
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='col-auto'>
                              <button
                                type='submit'
                                className='btn btn-primary chat-send w-md waves-effect waves-light d-flex align-items-center justify-content-center'
                                onClick={handleSendMessage}
                              >
                                <span className='d-none d-sm-inline-block me-2'>
                                  Invia
                                </span>

                                <Invia />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoSpeech;
