import React, { useState, useEffect, useContext } from "react";
import $ from "jquery";
import AvatarPink from "../img/avatarPink.png";
import { StateContext } from "./StateContainer";
import Moment from "react-moment";
import { TbDotsVertical as Dots } from "react-icons/tb";
import Loading from "./Loading";
import ReactDOM from "react-dom";
import { marked } from "marked";  // Importa la libreria marked

const MacchinaDaScrivere = ({
  text,
  lastMessage,
  ButtonNavigation,
  row,
  primaryRGBA,
  index,
  ControlsBeforeSendingMessage,
}) => {

  console.log("sono in faq")
  const { dettagliProgetto, setIsTypingMachine, loading, setShowFormContactFollowChat } = useContext(StateContext);
  const [showTipsQuestions, setShowTipsQuestions] = useState(false);

  // Regex per link e iframe
  const linkRegex = /<iframe[^>]*>.*?<\/iframe>|(https?:\/\/[^\s]+)/gi;
  const iframeRegex = /<iframe[^>]*>[\s\S]*?<\/iframe>/gi;
  const mailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  const textLines = text.split(/\n|<br>/).filter((el) => !el.length < 1);
  let conversationList = document.getElementsByClassName("botResponses");

  const animateElements = async () => {
    const elementsArray = Array.from(conversationList);
    const filteredList = elementsArray.filter((currentElement) => {
      return !currentElement.classList.contains("animationDone");
    });
  
    let lastElement = filteredList[filteredList.length - 1];
  
    // Mostra il loading alla fine, se non è già presente
    let globalLoading = document.querySelector(".global-loading");
    if (!globalLoading) {
      globalLoading = document.createElement("div");
      globalLoading.className = "global-loading";
  
      const loading = <Loading primaryRGBA={primaryRGBA} />;
      ReactDOM.render(loading, globalLoading);
  
      // Aggiungi il loading alla fine della lista
      if (filteredList.length > 0) {
        filteredList[filteredList.length - 1].parentNode.appendChild(globalLoading);
      }
    }
  
    for (let i = 0; i < filteredList.length; i++) {
      const currentElement = filteredList[i];
  
      // Ritarda l'animazione di ciascun messaggio
      await new Promise((resolve) => {
        setTimeout(() => {
          currentElement.style.display = "block";
          currentElement.style.opacity = "1";
          resolve();
          setIsTypingMachine(currentElement);
        }, i * 800); // Ritardo aumentato (800ms)
      });
  
      currentElement.classList.add("animationDone");
  
      // Se siamo all'ultimo messaggio, rimuovi il loading subito dopo
      if (currentElement === lastElement) {
        if (globalLoading && globalLoading.parentNode) {
          globalLoading.parentNode.removeChild(globalLoading);
        }
  
        var faqActions = document.querySelectorAll(".faq-actions");
        faqActions.forEach((element) => {
          if (!element.classList.contains("d-none")) {
            element.classList.remove("hideElement");
            element.classList.add("animationDoneActionsFaq");
          }
        });
  
        setShowFormContactFollowChat(true);
      }
    }
  };
  
    

  useEffect(() => {
    animateElements();
  }, []);

  const HideTipsQuestions = (messaggio, actions, index) => {
    var faqActions = document.querySelectorAll(".faq-actions");
    faqActions.forEach(function (element, index) {
      element.classList.remove("animationDoneActionsFaq");
      element.classList.add("d-none");
    });
    ControlsBeforeSendingMessage(messaggio);
  };

  const HideTipsQuestionsByLink = () => {
    var faqActions = document.querySelectorAll(".faq-actions");
    faqActions.forEach(function (element, index) {
      element.classList.remove("animationDoneActionsFaq");
      element.classList.add("d-none");
    });
  };


  const addTargetToLinks = (text) => {
    // Regex per trovare i link (http, https, mailto)
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    const mailRegex = /(\S+@\S+\.\S+)/g;
  
    // Aggiungi target="_blank" ai link
    text = text.replace(linkRegex, (match) => {
      return `<a href="${match}" target="_blank">${match}</a>`;
    });
  
    // Aggiungi target="_blank" ai mailto
    text = text.replace(mailRegex, (match) => {
      return `<a href="mailto:${match}" target="_blank">${match}</a>`;
    });
  
    return text;
  };
  
  return (
    <li className="pb-5 message-bot bg-orange">
      <div className="d-flex align-items-start gap-2">
        <div
          style={{
            height: "2.4vw",
            width: "2.4vw",
            minHeight: "16px",
            minWidth: "16px",
            borderRadius: "100%",
            background: `url(${
              dettagliProgetto && dettagliProgetto.chatbotIcon
                ? dettagliProgetto.chatbotIcon
                : AvatarPink
            }) center center / cover no-repeat`,
          }}
          className=""
        ></div>
        <div className="m-0 p-0">
          <h5 className="font-size-14 conversation-name">
            <a href="#" className="text-dark text-decoration-none">
              {dettagliProgetto ? dettagliProgetto.assistantName : ""}
            </a>{" "}
          </h5>

          {textLines.map((line, index) => (
            <div
              className="conversation-list m-0 botResponses"
              style={{ maxWidth: "400px" }}
              key={index}
            >
              <div className="ctext-wrap mt-2 m-0">
                <div
                  className="ctext-wrap-content m-0 text-dark mb-1"
                  style={{
                    background: `${primaryRGBA}`,
                    maxWidth: "300px",
                    hyphens: "auto",
                  }}
                >
                  <div
                    className=""
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: marked(line).replace(/<a /g, "<a target='_blank' "), // Aggiungi target='_blank' qui
                    }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <span
        className="d-inline-block font-size-12 text-muted"
        style={{ marginLeft: "24px" }}
      >
        <Moment format="HH:mm">{row.botMessage.date}</Moment>
      </span>

      <div className="w-100 d-grid justify-content-end gap-1">
        {row.botMessage.faq_actions &&
          row.botMessage.faq_actions[0] &&
          row.botMessage.faq_actions[0].length > 0 &&
          row.botMessage.faq_actions[0].map((action, indexAction) => {
            return action.type === "link" ? (
              <a
                href={action.content}
                target="_blank"
                className="btn bg-transparent mt-2 mb-1 font-size-14 btn-tips-questions faq-actions hideElement"
                style={{
                  minWidth: "180px",
                  border: `1px ${dettagliProgetto.primaryColor} solid`,
                  color: `${dettagliProgetto.primaryColor}`,
                }}
                onClick={HideTipsQuestionsByLink}
              >
                {action.etichetta}
              </a>
            ) : (
              <button
                className="btn mt-2 mb-1 font-size-14 faq-actions btn-tips-questions px-2 hideElement"
                style={{
                  border: `1px ${dettagliProgetto.primaryColor} solid`,
                  color: `${dettagliProgetto.primaryColor}`,
                }}
                onClick={() =>
                  HideTipsQuestions(
                    action.content,
                    row.botMessage.faq_actions[0],
                    indexAction
                  )
                }
              >
                {action.etichetta}
              </button>
            );
          })}
      </div>
    </li>
  );
};

export default MacchinaDaScrivere;
