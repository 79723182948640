import React from "react";

const Loading = ({ primaryRGBA }) => {
  return (
    <div class="conversation-list m-0" style={{maxWidth:"400px", display: "block", opacity: 1, border: "0px", padding: "0px"}}>
    <div className='ctext-wrap mt-2 py-2'>
      <div
        className='ctext-wrap-content m-0 d-flex align-items-center'
        style={{
          background: `${primaryRGBA}`,
          maxWidth: "300px",
          hyphens: "auto",
        }}
      >
        <div className='form-outline'>
          <div className='loading-dots'>
            <div className='loading-dots--dot-conversation'></div>
            <div className='loading-dots--dot-conversation'></div>
            <div className='loading-dots--dot-conversation'></div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Loading;
